/////////// Import ///////////
import React, { useState, useRef } from "react";
import JSZip from 'jszip';
import logoRecog from './images/logorecog_modified2.webp';
import logoHoval from './images/logohoval_modified.webp';

function App() {
  /////////// parameters ///////////
  const shouldReplaceShx = true;
  const imageWidth = 600; //#pixels
  const headerStyle = {
    color: "#ffffff",
    fontFamily: "Arial",
    fontSize: "100px",
    textAlign: 'center'
  };
  const infoStyle = {
    color: "#ffffff",
    fontFamily: "Arial",
    fontSize: "16px", // 'px' is a string value assigned to fontSize
    cursor: 'pointer',
    marginTop: '10px',
    transition: 'max-height 0.5s ease-out',
    overflow: 'hidden',
    width: imageWidth, // Ensuring the same width as other boxes
  };
  const hiddenStyle = {
    maxHeight: 0,
  };
  const visibleStyle = {
    maxHeight: '500px', // Increase max-height to accommodate more text
    paddingBottom: '40px' // Added more padding to prevent overlap
  };
  const infoTextStyle = {
    textAlign: 'left',
    whiteSpace: 'pre-wrap', // Ensuring text wraps within the box
  };

  const listStyle = {
    paddingLeft: '20px',
  };

  /////////// initialize ///////////
  const banned_product_ids = [5047855, 5047856, 5047892, 5047893, 5049060, 
                              5049203, 5049204, 5049849,5050197, 5050364, 
                              5051421, 5051662, 5047789, 5047790, 5047901, 
                              5052530, 5052621, 5051617, 5050198, 5049646, 
                              5049645, 5050204, 5049784, 5047902, 5049785, 
                              5050119, 5053745, 5053956, 5054150, 5053957, 
                              5055439, 5047903, 5047904, 5050206, 5050207, 
                              5047905, 5048067, 5050205];
  
  const [files, setFiles] = useState([]);
  const [finishedFileList, setFinishedFileList] = useState([]);
  const [editedFiles, setEditedFiles] = useState([]);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const dxfFiles = selectedFiles.filter(file => 
      (file.name.toLowerCase().endsWith('.dxf') || 
       file.name.toLowerCase().endsWith('.step') || 
       file.name.toLowerCase().endsWith('.stp')) && 
      !files.some(f => f.name === file.name)
    );
    setFiles(prevFiles => [...prevFiles, ...dxfFiles]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    const dxfFiles = droppedFiles.filter(file => 
      (file.name.toLowerCase().endsWith('.dxf') || 
       file.name.toLowerCase().endsWith('.step') || 
       file.name.toLowerCase().endsWith('.stp')) && 
      !files.some(f => f.name === file.name)
    );
    setFiles(prevFiles => [...prevFiles, ...dxfFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearFiles = () => {
    setFiles([]);
    setFinishedFileList([]);
    setEditedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  /////////// Help functions //////////////
  function handleUpload() {
    if (files.length === 0) {
      console.log("No file selected");
      return;
    }
    const edited = [];
    const promises = files.map((file) => {
      const fileName = file.name;
      if (fileName.toLowerCase().endsWith('.dxf')) {
        return file.text().then((txtValue) => {
          let lines = txtValue.split('\r\n');
          let processedLines = lines.map(line => line.replace(/^\s+(\d)/, '$1').trim());
          if (shouldReplaceShx) {
            processedLines = processedLines.map(line => line.replace(/isocp\.shx/g, 'txt.shx'));
          }
          const AcDbindices = processedLines.map((line, index) => line === "AcDbText" ? index : -1).filter(index => index !== -1);
          let isEdited = false;
          if (AcDbindices.length <= 0) {
            const underScoreIndex = fileName.indexOf('_');
            const fileNameNums = fileName.substring(underScoreIndex - 5, underScoreIndex);
            const { centerX, centerY, centerZ } = calculateBoundingBoxCenter(processedLines);
            processedLines = addTextEntity(processedLines, centerX, centerY, centerZ, fileNameNums);
          }
          for (let iPar = 0; iPar < AcDbindices.length; iPar += 2) {
            const startIdx = AcDbindices[iPar] + 1;
            const endIdx = AcDbindices[iPar + 1];
            for (let jIndices = startIdx; jIndices < endIdx; jIndices += 2) {
              const key = parseFloat(processedLines[jIndices]);
              if (key === 1) {
                const strToEdit = processedLines[jIndices + 1];
                const slashIndex = strToEdit.indexOf('/');
                let beforeBreak, afterBreak;
                if (slashIndex !== -1) {
                  beforeBreak = strToEdit.substring(0, slashIndex);
                  afterBreak = strToEdit.substring(slashIndex);
                } else {
                  beforeBreak = strToEdit;
                  afterBreak = '';
                }
                const underScoreIndex = fileName.indexOf('_');
                const fileNameNums = fileName.substring(underScoreIndex - 5, underScoreIndex);
                processedLines[jIndices + 1] = fileNameNums + afterBreak;
                isEdited = true;
              }
            }
          }
          if (isEdited) {
            edited.push({ name: fileName, edited: true });
          } else {
            edited.push({ name: fileName, edited: false });
          }
          const filePackage = {
            filename: fileName,
            content: processedLines
          };
          setFinishedFileList((prevList) => [...prevList, filePackage]);
          setEditedFiles(edited);
        });
      } else if (fileName.toLowerCase().endsWith('.step') || fileName.toLowerCase().endsWith('.stp')) {
        return file.text().then((txtValue) => {
          return processStepFile(file, banned_product_ids).then((processedLines) => {
            const processedLines2 = replaceScandinavianCharacters(processedLines);
            const isEdited = processedLines2 !== txtValue; // Check if the file was modified
            if (isEdited) {
              edited.push({ name: fileName, edited: true });
            } else {
              edited.push({ name: fileName, edited: false });
            }
            const filePackage = {
              filename: fileName,
              content: processedLines2
            };
            setFinishedFileList((prevList) => [...prevList, filePackage]);
            setEditedFiles(edited);
          });
        });
      }
    });

    Promise.all(promises).then(() => {
      console.log("All files processed");
    });
  }

  function findIntegerEndIndex(str, startIndex) {
    // Start looping from the startIndex
    let i = startIndex;

    // Continue as long as we encounter digits
    while (i < str.length && /\d/.test(str[i])) {
        i++;
    }

    // Return the index where the integer ends
    return i;
  }

  function processStepFile(file, banned_product_ids) {
    // take out 5 last digets and exta number from filename
    const fileName = file.name;
    const firstUnderscoreIdx = fileName.indexOf('_');
    const secondUnderscoreIdx = findIntegerEndIndex(fileName, firstUnderscoreIdx + 1)
    var startIdx = 0
    if (firstUnderscoreIdx >= 5) {
      startIdx = firstUnderscoreIdx - 5
    }
    const file_name_string_start = fileName.substring(startIdx, secondUnderscoreIdx) + "_"; // Includes both underscores
    return file.text().then(txtValue => {
      const lines = txtValue.split(/\r?\n/); // Ensure we handle both LF and CRLF line endings
      const processedLines = lines.map(line => {
        // Match the product line that starts with '5' and capture the product ID
        const productMatch = line.match(/^#\d+=PRODUCT\('5(\d+)_/);
        if (productMatch) {
          const product_id = parseInt("5" + productMatch[1]); // Extract the product ID
          let newLine = line;
          if (banned_product_ids.includes(product_id)) {
            // For banned product, replace the first quoted section with just the product_id and empty the rest
            newLine = line.replace(/'[^']*'/g, `''`).replace(/'[^']*'/, `'${product_id}'`);
          } else {
            // For non-banned product, insert file_name_string_start + product_id in the first quotes and empty the rest
            const newProductInfo = `${file_name_string_start}${product_id}`;
            newLine = line.replace(/'[^']*'/g, `''`).replace(/'[^']*'/, `'${newProductInfo}'`);
          }
  
          return newLine;
        }
  
        return line; // Return unchanged lines
      });
  
      // Join the processed lines back together with proper line breaks
      return processedLines.join('\r\n'); // Use '\r\n' for Windows-style line endings
    });
  }
  
  function replaceScandinavianCharacters(input) {
    // This regex finds text inside single quotes
    const regex = /'([^']*)'/g;

    // Function to replace å, ä, ö with their ASCII equivalents (both lowercase and uppercase)
    function replaceScandChars(str) {
        return str.replace(/å/g, 'a')
                  .replace(/ä/g, 'a')
                  .replace(/ö/g, 'o')
                  .replace(/Å/g, 'A')
                  .replace(/Ä/g, 'A')
                  .replace(/Ö/g, 'O')
                  .replace(/\\X2\\00E4\\X0\\/g, 'a')  // Match and replace encoded ä
                  .replace(/\\X2\\00C4\\X0\\/g, 'A')  // Match and replace encoded Ä
                  .replace(/\\X2\\00E5\\X0\\/g, 'a')  // Match and replace encoded å
                  .replace(/\\X2\\00C5\\X0\\/g, 'A')  // Match and replace encoded Å
                  .replace(/\\X2\\00F6\\X0\\/g, 'o')  // Match and replace encoded ö
                  .replace(/\\X2\\00D6\\X0\\/g, 'O'); // Match and replace encoded Ö
    }

    // Replace in each section found inside single quotes
    return input.replace(regex, (match, p1) => {
        // Replace å, ä, ö (and capitals) only inside the matched section within single quotes
        const replacedSection = replaceScandChars(p1);
        return `'${replacedSection}'`; // Put the replaced section back in quotes
    });
}

  function calculateBoundingBoxCenter(dxfLines) {
    let minX = Infinity, minY = Infinity, minZ = Infinity;
    let maxX = -Infinity, maxY = -Infinity, maxZ = -Infinity;
    function updateBounds(x, y, z) {
        if (x < minX) minX = x;
        if (x > maxX) maxX = x;
        if (y < minY) minY = y;
        if (y > maxY) maxY = y;
        if (z < minZ) minZ = z;
        if (z > maxZ) maxZ = z;
    }
    for (let i = 0; i < dxfLines.length; i++) {
        const line = dxfLines[i].trim();
        if (line === 'LINE' || line === 'CIRCLE' || line === 'ARC' || line === 'POINT' || line === 'TEXT') {
            let x = 0, y = 0, z = 0;
            let radius = 0;
            let isCircle = (line === 'CIRCLE' || line === 'ARC');
            while (dxfLines[++i].trim() !== '0') {
                const code = dxfLines[i].trim();
                const value = parseFloat(dxfLines[++i].trim());
                switch (code) {
                    case '10': x = value; break;
                    case '20': y = value; break;
                    case '30': z = value; break;
                    case '40': if (isCircle) radius = value; break;
                    case '11':
                        if (line === 'LINE') {
                            updateBounds(value, y, z);
                        }
                        break;
                    case '21':
                        if (line === 'LINE') {
                            updateBounds(x, value, z);
                        }
                        break;
                    case '31':
                        if (line === 'LINE') {
                            updateBounds(x, y, value);
                        }
                        break;
                }
            }
            if (line === 'CIRCLE') {
                updateBounds(x - radius, y - radius, z);
                updateBounds(x + radius, y + radius, z);
            } else {
                updateBounds(x, y, z);
            }
        }
    }
    const centerX = (minX + maxX) / 2;
    const centerY = (minY + maxY) / 2;
    const centerZ = (minZ + maxZ) / 2;
    return { centerX, centerY, centerZ };
  }

  function addTextEntity(dxfLines, posX, posY, posZ, textValue, layerName = "0", textHeight = 5.0, textWidth = 1.007194) {
    const textEntity = [
      '0', 'TEXT',
      '5', generateHandle(dxfLines), 
      '100', 'AcDbEntity',
      '8', layerName,
      '62', '7', 
      '370', '13',
      '100', 'AcDbText',
      '10', posX.toString(),
      '20', posY.toString(),
      '30', posZ.toString(),
      '40', textHeight.toString(),
      '1', textValue,
      '41', textWidth.toString(),
      '72', '5',
      '11', posX.toString(),
      '21', posY.toString(),
      '31', posZ.toString(),
      '100', 'AcDbText', 
    ];

    let entitiesStartIndex = -1;
    let entitiesEndIndex = -1;

    for (let i = 0; i < dxfLines.length; i++) {
      if (dxfLines[i].trim().toUpperCase() === 'SECTION' && dxfLines[i + 2].trim().toUpperCase() === 'ENTITIES') {
        entitiesStartIndex = i + 4;
      }
      if (entitiesStartIndex !== -1 && dxfLines[i].trim().toUpperCase() === 'ENDSEC') {
        entitiesEndIndex = i - 1;
        break;
      }
    }

    if (entitiesStartIndex === -1 || entitiesEndIndex === -1) {
      throw new Error('ENTITIES section not found or malformed');
    }

    dxfLines.splice(entitiesEndIndex, 0, ...textEntity);
    return dxfLines;
  }

  function generateHandle(dxfLines) {
    let maxHandle = 0;
    for (let i = 0; i < dxfLines.length; i++) {
      if (dxfLines[i].trim() === '5') {
        const handle = parseInt(dxfLines[i + 1], 16);
        if (!isNaN(handle) && handle > maxHandle) {
          maxHandle = handle;
        }
      }
    }
    return (maxHandle + 1).toString(16).toUpperCase();
  }

  async function saveZip() {
    const zip = new JSZip();
    finishedFileList.forEach(file => {
      const content = file.content.join ? file.content.join('\r\n') : file.content;
      zip.file(file.filename, content);
    });
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(zipBlob);
    link.download = 'dxf_and_step_files.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    clearFiles();
  }

  const buttonStyle = {
    backgroundColor: '#e30515',
    border: 'none',
    color: 'white',
    padding: '15px 32px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  };

  const toggleInfoVisibility = () => {
    setIsInfoVisible(!isInfoVisible);
  };

/////////// main HTML code ///////////
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <img src={logoHoval} alt="hoval logo" width={imageWidth} />
      {/* <div style={headerStyle}>detalj justerare</div> */}
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <div style={infoStyle} onClick={toggleInfoVisibility}>
          <p style={{ textAlign: 'center' }}><u>Info</u></p>
          <div style={{ ...infoTextStyle, ...isInfoVisible ? visibleStyle : hiddenStyle }}>
            <p>Denna sida används till att ladda upp DXF och STEP filer i bulk, ändra textinnehållet för DXF filer och ladda ner dem.</p>
            <p>Instruktioner:</p>
            <ul style={listStyle}>
              <li>Dra in dina DXF eller STEP filer i den sträckade rutan. Du kan välja flera filer samtidigt.</li>
              <li>Tryck på "Process" knappen för att processera filerna. Endast DXF filer kommer bearbetas just nu.</li>
              <li>Efter detta blir de filer som hade ett textobjekt i sig från början grönmarkerade.</li>
              <li>Klicka "Save File" knappen för att ladda ner dem i ett zip-format.</li>
              <li>Klicka "Clear" eller uppdatera sidan för att återställa programet.</li>
            </ul>
          </div>
        </div>
      </div>
      <div 
        onDrop={handleDrop} 
        onDragOver={handleDragOver} 
        style={{ 
          border: '2px dashed #cccccc', 
          backgroundColor: '#ffffff',
          padding: '20px', 
          marginBottom: '20px',
          width: imageWidth,
          textAlign: 'center',
          borderRadius: '10px'
        }}
        onClick={openFileDialog}
      >
        {files.length === 0 ? (
          <>
            Drag and drop your DXF or STEP files here, or click to select files.
          </>
        ) : (
          <div>
            <h4>Files Added:</h4>
            <ul style={listStyle}>
              {files.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}
        <input 
          type="file" 
          name="dfx" 
          multiple 
          onChange={handleFileChange} 
          ref={fileInputRef} 
          style={{ display: 'none' }} 
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <button style={buttonStyle} onClick={openFileDialog}>Add Files</button>
        <button style={buttonStyle} onClick={clearFiles}>Clear</button>
        {files.length > 0 && <button style={buttonStyle} onClick={handleUpload}>Process</button>}
      </div>
      {editedFiles.length > 0 && (
        <>
          <div 
            style={{ 
              border: '2px solid #cccccc', 
              backgroundColor: '#ffffff',
              padding: '20px', 
              marginTop: '20px',
              width: imageWidth,
              textAlign: 'center',
              borderRadius: '10px',
              marginBottom: '20px'
            }}
          >
            <h4>Files Status:</h4>
            <ul style={listStyle}>
              {editedFiles.map((file, index) => (
                <li key={index} style={{ color: file.edited ? 'green' : 'black' }}>
                  {file.name}
                </li>
              ))}
            </ul>
          </div>
          {finishedFileList.length > 0 && (
            <button style={{ ...buttonStyle, marginTop: '20px' }} onClick={saveZip}>Save Files</button>
          )}
        </>
      )}
      <img src={logoRecog} alt="recog logo" width={imageWidth} />
    </div>
  );
}

export default App;
